<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="订单编号:">
                    <el-input class="s-input" v-model="queryParams.ordersn" placeholder="请输入订单编号" clearable></el-input>
                </el-form-item>
                <el-form-item label="支付状态:">
                    <el-select v-model="queryParams.pay_state" placeholder="请选择支付状态" clearable @change="changeQuery">
                        <el-option label="未支付" :value="0"></el-option>
                        <el-option label="已支付" :value="2"></el-option>
                    </el-select>
                </el-form-item>

                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>

            </el-form>
            <div style="float: right">
                <el-button type="primary" @click="changeAddOrder()">手动创建订单</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list" style="margin-top: 10px;">
                <el-table-column label="id" prop="id" width="100" align="center"></el-table-column>
                <el-table-column label="等级名称" prop="title" align="center"></el-table-column>
                <el-table-column label="分配会员邀请码数量" prop="invite_code_number" align="center"></el-table-column>
                <el-table-column label="订单号" prop="ordersn" align="center" width="200"></el-table-column>
                <el-table-column label="支付时间 " prop="pay_time" width="200" align="center"></el-table-column>
                <el-table-column label="价格" prop="price" width="200" align="center"></el-table-column>
                <el-table-column label="支付方式" prop="pay_type" width="200" align="center">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.pay_type === 1">微信</el-tag>
                        <el-tag type="primary" v-if="scope.row.pay_type === 2">支付宝</el-tag>
                        <el-tag type="primary" v-if="scope.row.pay_type === 3">银行卡</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="支付状态" prop="pay_state" align="center" width="200">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.pay_state === 0">未支付</el-tag>
                        <el-tag type="primary" v-if="scope.row.pay_state === 2">已支付</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 手动创建 订单 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="50%">
            <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="right" label-suffix=":" label-width="150px" size="large" element-loading-text="上传中">
                <el-form-item label="选择等级" prop="gradeId">
                    <el-select v-model="form.gradeId" placeholder="请选择升级等级" >
                        <el-option  v-for="(grade,index) in gradeList"   :key="index" :label="grade.title" :value="grade.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-divider content-position="right"></el-divider>
                <el-form-item label="手机号查找" prop="consumption">
                    <el-input v-model="phone" placeholder="请输入完整手机号"></el-input>
                </el-form-item>
                <el-form-item label="选择会员" prop="customer">
                    <el-select v-model="form.uid" placeholder="请选择会员" >
                        <el-option  v-for="(user,index) in userList"   :key="index" :label="user.phone+':'+user.grade_name" :value="user.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-divider content-position="right"></el-divider>
                <el-form-item label="订单金额" prop="shipping">
                    <el-input v-model="gradePayAmount"></el-input>
                </el-form-item>
                <el-form-item label="支付方式" prop="shop">
                    <el-select v-model="form.payType" placeholder="请选择支付状态" >
                        <el-option  v-for="(item,index) in PayTypeMap"   :key="item.key" :label="item.text" :value="item.key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="升级描述" prop="shipping">
                    <el-tag type="success">{{ gradeRemark }}</el-tag>
                </el-form-item>
                <el-form-item label="支付截图" prop="form">
                    <Imgupload v-model="form.thumbs" :ImgVisible="ImgVisible" @changeImg="changeImg" @changetext="changetext" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb'
import Pagination from '@/components/Pagination'
import Imgupload from '@/components/Imgupload';
import PayTypeMap from "@/common/const";
import Picture from '@/components/Picture';
import { request } from '@/common/request'
import { deal } from '@/common/main'
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination,
        Imgupload,
        Picture
    },
    data() {
        return {
            menuList: ['客户', '等级订单'],
            loading: false,
            confirmContent: '',
            queryParams: {
                page: 1,
                pagesize: 10,

            },
            pageInfo: {},
            addVisible: false,
            addVisibles: false,
            ImgVisible:false,
            title: '创建等级订单',
            rules: {
                name: [
                    { required: true, message: '商城名称不能为空', trigger: 'blur' }
                ]
            },

            mode: 'add',
            form: {
                gradeId:0, //选中的等级id
                uid:0,  //选中的用户id
                amount:0.00,
                payType:"",
                remark:"",
                thumbs:[]
            },
            phone:"",//输入的手机号
            gradeList:[],  //会员等级列表
            gradePayAmount:"0.00",
            gradeRemark:"0.00",
            userList:[],  //

            text:"",
            PayTypeMap: [
                {"text": "微信支付", "key": "WechatPay","id":1},
                {"text": "支付宝", "key": "Alipay","id":2},
                {"text": "银行卡", "key": "bank","id":3},
            ], //支付方式

        }
    },
    created() {
        this.loadData();
    },
    watch: {

        phone(now ,old){
            now=now.trim()
            if (now!==old && now.length ===11){
                this.getMemberList(now)
            }
        },
        'form.gradeId':{
            handler:function (now ,old){
                for (const gradeKey in this.gradeList) {
                    console.log(this.gradeList[gradeKey])
                    console.log(now)
                    if (this.gradeList[gradeKey].id===now){
                        this.gradePayAmount=this.gradeList[gradeKey].price
                        this.gradeRemark=this.gradeList[gradeKey].desc
                    }
                }
            },
        }
    },
    methods: {
        loadData() {
            this.loading = true;
            //等级订单列表
            request.get('/account/grade/order/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        if (val.pay_time == 0) {
                            val.pay_time = ""
                        } else {
                            val.pay_time = deal.timestamp(val.pay_time, 'unix');
                        }

                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });

            request.get('/account/grade/goods/list', this.queryParams).then(ret => {
                if (ret.code === 1) {
                    this.gradeList = ret.data.list;
                    console.log(this.gradeList)
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑';
            const item = this.pageInfo.list[index];
            let time = [item.stime, item.etime]
            //处理时间
            this.form = Object.assign({}, item);
            // console.log(4444,this.form)
            this.$set(this.form, 'time', time);
            console.log(1111111, this.form)
            this.addVisible = true;
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //   this.form.num = this.form.countType == 1 ? 1 : this.form.num;
                        // this.form.brand_ids = this.form.brand_id;
                        let url =  '/account/grade/order/open' ;
                        request.post(url, this.form).then(ret => {
                            if (ret.code === 1) {
                                this.addVisible = false;
                                this.addVisibles = false;
                                this.loadData();
                                this.$message.success((this.mode === 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/account/grade/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        addOrder(){
            request.post('/account/grade/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        changeAddOrder(){
            this.addVisible=!this.addVisible
        },
        getMemberList(phone){
            request.get('/account/list', {phone:phone}).then(ret => {
                if (ret.code === 1) {
                   this.userList=ret.data.list
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        changeImg(val) {
            this.ImgVisible = val
        },
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            if (this.text === 'many') {
                if (this.form.thumbs.length < 9) {
                    this.form.thumbs.push(url)
                }
                else {
                    this.$message({ type: 'error', message: '最多上传九张图片' })
                }
            } else {
                this.$set(this.form.specList1[this.text], 'thumb', url)
                this.ImgVisible = false
            }
        },
        changetext(val) {
            this.text = val
        },
    }
}
</script>
<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}
</style>
